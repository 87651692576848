import React from "react"
import $ from 'jquery';
import ProgressBar from 'progressbar.js'
import { Background } from './common/background-image.component';
import {RichText} from 'prismic-reactjs';
import { linkResolver } from "../link-resolver";
import { Image } from "./common/image.component"

export class Header extends React.Component {
  componentDidMount() {
    const slider = $(".slider");
    const sliderNav = $(".slider-nav");
    const sliderCount = $(".slider-counter__count");
    const time = 7;
    let $progressBar,
      $bar,
      $elem,
      isPause,
      tick,
      percentTime,
      circle;

    if (document.querySelector(".circle-progress")) {
      circle = new ProgressBar.Circle(".circle-progress", {
        color: "#205a9c",
        strokeWidth: 2,
        trailWidth: 1,
        duration: 600,
      })
    }

    function progressBar(elem) {
      $elem = elem
      //build progress bar elements
      buildProgressBar()
      //start counting
      start()
    }

    function buildProgressBar() {
      $progressBar = $("<div>", {
        id: "progressBar",
      })
      $bar = $("<div>", {
        id: "bar",
      })
      $progressBar.append($bar).prependTo($elem)
    }

    function start() {
      //reset timer
      percentTime = 0
      isPause = false
      //run interval every 0.01 second
      tick = setInterval(interval, 10)
    }

    function interval() {
      if (isPause === false) {
        percentTime += 1 / time
        $bar.css({
          width: percentTime + "%",
        })

        //if percentTime is equal or greater than 100
        circle.set(percentTime / 100)
        if (percentTime >= 100) {
          //slide to next item
          $elem.trigger("owl.next")
        }
      }
    }

    //pause while dragging
    function pauseOnDragging() {
      isPause = true
    }

    //moved callback
    function moved() {
      //clear interval
      clearTimeout(tick)
      //start again
      start()
    }

    function afterAction() {
      const self = $(this)
      sliderCount.text(self[0].currentItem + 1)
      sliderNav.find("li").removeClass("active")
      sliderNav.find("li").eq(self[0].currentItem).addClass("active")
    }

    slider.owlCarousel({
      singleItem: true,
      pagination: false,
      paginationNumbers: true,
      slideSpeed: 600,
      paginationSpeed: 600,
      afterInit: progressBar,
      afterMove: moved,
      startDragging: pauseOnDragging,
      afterAction: afterAction,
    })

    sliderNav.on("click", "li", function(e) {
      e.preventDefault()
      slider.trigger("owl.goTo", $(this).index())
    })
  }

  render() {
    const { headerSlides } = this.props
    if (headerSlides.length === 1) {
      const slide = headerSlides[0]
      const hasImage = slide.background_image;
      return (
        <Background
          className={`header ${hasImage ? 'header-dark' : 'header-light'}`}
          image={slide.background_image}
          style={{backgroundColor: (hasImage ? 'transparent' : '#f8f8f8')}}>
          <div className="slider__wrap">
          </div>
          <div className="container">
            <div className="row">
              <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
                <div className="section">
                  <RichText className="section" render={slide.content} linkResolver={linkResolver}/>
                </div>
              </div>
            </div>
          </div>
        </Background>
      )
    } else if (headerSlides.length > 1) {
      return (
        <header className="header_home">
          <div className="progress-container">
            <div className="container">
              <div className="circle-progress">
                <p className="slider-counter"><span className="slider-counter__count">1</span>/{headerSlides.length}
                </p>
              </div>
            </div>
          </div>
          <div className="slider">
            {headerSlides.map(slide => (
              <Background image={slide.background_image} className="slide">
                <div className="slider__wrap"></div>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="section">
                        <RichText render={slide.content} linkResolver={linkResolver}/>
                      </div>
                      <br/>
                    </div>
                  </div>
                </div>
              </Background>
            ))}
          </div>
          <div className="nav-container">
            <div className="container">
              <ul className="slider-nav nav nav-pills">
                {
                  headerSlides.map(slide => (
                    <li>
                      <a>
                        <div style={{ height: "26px", width: "50px" }}><Image className="height-100"
                                                                              image={slide.icon}/></div>
                        <br/>
                        <small className="visible-lg visible-md text-uppercase"
                               style={{ maxWidth: "50px" }}>{slide.label}</small>
                      </a>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </header>
      )
    } else {
      return null
    }
  }
}
