import React from "react"
import Layout from "./layout.component"
import { Navbar } from "./navbar.component"
import PropTypes from 'prop-types';
import { Header } from "./header.component"
import { Slices } from "./slices.component"
import { RequestQuote } from "./request-quote.component"
import { AsideNavigation } from "./common/aside-navigation.component"
import StickyBox from "react-sticky-box";
import SEO from "./common/seo.component"


class ContentPage extends React.Component {
  componentDidMount() {

  }

  render() {
    const { pageContext } = this.props
    const { header_slides, body, aside_navigation } = pageContext;
    return (
      <Layout lang={pageContext._meta.lang}>
        <SEO
          title={pageContext.page_title || pageContext.title}
          description={pageContext.description || pageContext.text}
          keywords={pageContext.keywords}
          image={pageContext.preview_image}
          lang={pageContext._meta.lang}
        />
        <Navbar slides={header_slides} lang={pageContext._meta.lang}/>
        <Header headerSlides={header_slides}/>
        <main className="main_alt">
        {aside_navigation ? (
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-menu">
                <StickyBox offsetTop={0} offsetBottom={50}>
                  <AsideNavigation navigation={aside_navigation}/>
                </StickyBox>
              </div>
              <div className="col-slices"> <Slices body={body} large={header_slides.length > 1} /></div>
            </div>
          </div>
        ) :
          <Slices body={body} large={header_slides.length > 1} />
             }
        </main>
        <RequestQuote lang={pageContext._meta.lang} />
      </Layout>
    )
  }
}

ContentPage.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default ContentPage



